import axios from 'axios';
import { Callback } from 'yup/lib/types';
import { IHttpResponse } from './httpResponse';

function defaultErrorHandler(error: any) {
    console.log(error);
}

export async function uiPost(url: string, data: any, errorHandler: Callback = defaultErrorHandler): Promise<IHttpResponse | undefined> {
    try {
        const resp: IHttpResponse = await axios.post(url, data);
        return resp;
    } catch (error: any) {
        errorHandler(error);
    }
}

export async function uiGet(url: string, errorHandler: Callback = defaultErrorHandler): Promise<IHttpResponse | undefined> {
    try {
        const resp: IHttpResponse = await axios.get(url);
        return resp;
    } catch (error: any) {
        errorHandler(error);
    }
}

export async function uiPut(url: string, data: any, errorHandler: Callback = defaultErrorHandler): Promise<IHttpResponse | undefined> {
    try {
        const resp: IHttpResponse = await axios.put(url, data);
        return resp;
    } catch (error: any) {
        errorHandler(error);
    }
}

export async function uiDelete(url: string, errorHandler: Callback = defaultErrorHandler): Promise<IHttpResponse | undefined> {
    try {
        const resp: IHttpResponse = await axios.delete(url);
        return resp;
    } catch (error: any) {
        errorHandler(error);
    }
}

export async function uiPatch(url: string, data: any, errorHandler: Callback = defaultErrorHandler): Promise<IHttpResponse | undefined> {
    try {
        const resp: IHttpResponse = await axios.patch(url, data);
        return resp;
    } catch (error: any) {
        errorHandler(error);
    }
}

export async function Request(requestOptions: any, errorHandler: Callback = defaultErrorHandler): Promise<any | undefined> {
    try {
        return await axios(requestOptions);
    } catch (error: any) {
        errorHandler(error);
    }
}