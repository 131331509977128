import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './checkin.scss';
import { useTranslation } from 'react-i18next';
import '../../Translations/i18n';
import { BsFillTelephoneFill } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import { submitSpotData } from '../../Services/event-service';
import { ISpotData } from '../../Models/SpotData';
import LoadingSpinner from '../LoadingSpinner/loadingSpinner';
import {
    BFG_BACKGROUND_COLOR,
    BFG_CONCEPT_CODE,
    BFG_SITE,
    CIG_BACKGROUND_COLOR,
    CIG_CONCEPT_CODE,
    CIG_SITE, DEV_BFG_SITE,
    DEV_CIG_SITE,
    DEV_OBS_SITE,
    OBS_BACKGROUND_COLOR,
    OBS_CONCEPT_CODE,
    OBS_SITE,
    QA_BFG_SITE,
    QA_CIG_SITE,
    QA_OBS_SITE
} from '../../Common/constants';
import useFeatureFlags from '../../Hooks/useFeatureFlags';

function CheckIn() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [newsBannerEnabled, getFeatureSettings] = useFeatureFlags(null);

    const [orderId, setOrderId] = useState('');
    const [parkingSpotID, setParkingSpotID] = useState('');
    const [errorMessage, setError] = useState('');
    const [conceptRestaurantCode, setConceptRestaurantCode] = useState('');
    const [orderProcessingDate, setOrderProcessingDate] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [storePhoneNumber, setStorePhoneNumber] = useState('');

    const [concept, setConcept] = useState('NONE');
    const [submitButtonStyle, setSubmitButtonStyle] = useState('submit-button');
    const [backgroudStyle, setBackgroudStyle] = useState('background');
    const [spotinputStyle, setSpotinputStyle] = useState('spot-input');

    const formattedPhoneNumber = (phone) => {
        let cleaned = ('' + phone).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3]
        };
        return null
    }

    useEffect(() => {

        getFeatureSettings('newsBanner');

        const hostname = window.location.hostname;
        switch (hostname.toLocaleLowerCase()) {
            case DEV_BFG_SITE:
            case QA_BFG_SITE:
            case BFG_SITE:
                // case "localhost": // HINT: Uncomment if you want to test the favicon on the localhost
                setConcept(BFG_CONCEPT_CODE);
                setSubmitButtonStyle('bfg-submit-button');
                setBackgroudStyle('bfg-background');
                document.body.style.backgroundColor = BFG_BACKGROUND_COLOR;
                setSpotinputStyle('bfg-spot-input');
                break;

            case DEV_OBS_SITE:
            case QA_OBS_SITE:
            case OBS_SITE:
                // case "localhost": // HINT: Uncomment if you want to test the favicon on the localhost
                setConcept(OBS_CONCEPT_CODE);
                setSubmitButtonStyle('obs-submit-button');
                document.body.style.backgroundColor = OBS_BACKGROUND_COLOR;
                setBackgroudStyle('obs-background');
                setSpotinputStyle('obs-spot-input');
                break;

            case DEV_CIG_SITE:
            case QA_CIG_SITE:
            case CIG_SITE:
                // case "localhost": // HINT: Uncomment if you want to test the favicon on the localhost
                setConcept(CIG_CONCEPT_CODE);
                setSubmitButtonStyle('cig-submit-button');
                document.body.style.backgroundColor = CIG_BACKGROUND_COLOR;
                setBackgroudStyle('cig-background');
                setSpotinputStyle('cig-spot-input');
                break;

            default:
                setConcept('NONE');
        }

        const queryParams = location.search
        const searchParams = new URLSearchParams(queryParams)
        const p_orderId: string | null = searchParams.get('order-id');
        const p_conceptRestaurantCode: string | null = searchParams.get('concept-restaurant-code');
        const p_orderProcessingDate: string | null = searchParams.get('order-processing-date');
        const p_orderNumber: string | null = searchParams.get('order-number');
        const p_storePhoneNumber: string | null = searchParams.get('restaurant-phone-number');
        const p_concept: string | null = searchParams.get('concept-code');

        setOrderId(p_orderId ?? '');
        setConceptRestaurantCode(p_conceptRestaurantCode ?? '');
        setOrderProcessingDate(p_orderProcessingDate ?? '');
        setOrderNumber(p_orderNumber ?? '');
        setStorePhoneNumber(p_storePhoneNumber ?? '');

        if (p_concept) {
            switch (p_concept.toLocaleUpperCase()) {
                case BFG_CONCEPT_CODE:
                    setConcept(BFG_CONCEPT_CODE);
                    setSubmitButtonStyle('bfg-submit-button');
                    document.body.style.backgroundColor = BFG_BACKGROUND_COLOR;
                    setBackgroudStyle('bfg-background');
                    setSpotinputStyle('bfg-spot-input');
                    break;

                case OBS_CONCEPT_CODE:
                    setConcept(OBS_CONCEPT_CODE);
                    setSubmitButtonStyle('obs-submit-button');
                    document.body.style.backgroundColor = OBS_BACKGROUND_COLOR;
                    setBackgroudStyle('obs-background');
                    setSpotinputStyle('obs-spot-input');
                    break;

                case CIG_CONCEPT_CODE:
                    setConcept(CIG_CONCEPT_CODE);
                    setSubmitButtonStyle('cig-submit-button');
                    document.body.style.backgroundColor = CIG_BACKGROUND_COLOR;
                    setBackgroudStyle('cig-background');
                    setSpotinputStyle('cig-spot-input');
                    break;

                default:
                    setConcept('NONE');
            }
        }
    }, [location.search])

    const submitParkingInfo = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        if (!parkingSpotID || parseInt(parkingSpotID) < 1) {
            setError(t(`${concept.toLocaleLowerCase()}_validationErrors.invalidParkingSlot`));
            setIsLoading(false);
        } else {
            let spotData: ISpotData = {
                conceptRestaurantCode: conceptRestaurantCode,
                orderProcessingDate: orderProcessingDate,
                orderNumber: parseInt(orderNumber),
                parkingSpotNumber: parkingSpotID,
            };

            if (orderId && orderId != '') {
                const conceptId = orderId?.substring(0, 2);
                const storeId = orderId?.substring(2, 6);
                const year = orderId?.substring(6, 10);
                const month = orderId?.substring(10, 12);
                const date = orderId?.substring(12, 14);
                const _orderNumber = orderId?.substring(14, 18);

                spotData = {
                    conceptRestaurantCode: `${conceptId}_${storeId}`,
                    orderProcessingDate: `${year}-${month}-${date}`,
                    orderNumber: parseInt(_orderNumber),
                    parkingSpotNumber: parkingSpotID,
                };
            }

            submitSpotData(spotData)
                .then((response) => {
                    setIsLoading(false);

                    let _orderId = orderId;
                    if (!_orderId || _orderId === '') {
                        _orderId = `${conceptRestaurantCode.replace(/_/g, '')}${orderProcessingDate.replace(/-/g, '')}${orderNumber.toString().padStart(4, '0')}`;
                        setOrderId(_orderId);
                    }

                    if (response && _orderId && _orderId !== '') {
                        navigate(`/confirmation?order-id=${_orderId}&concept-code=${concept}`);
                    } else {
                        setError(t(`${concept.toLocaleLowerCase()}_validationErrors.genericErrorMessage`));
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(t(`${concept.toLocaleLowerCase()}_validationErrors.genericErrorMessage`));
                });
        }
    }

    const handleNumChange = (event) => {
        setError('');
        let isANumber = /^\d+$/.test(event.target.value);

        if (!isANumber || Number.isNaN(parseInt(event.target.value))) {
            setParkingSpotID('');
            return;
        }
        else if (event.target.value && event.target.value !== '' && event.target.value !== undefined) {

            let spot = event.target.value.replace(/[^0-9]/gi, '');
            if (spot) {
                const limit = 2;
                spot = event.target.value.slice(0, limit);
                setParkingSpotID(spot);
            } else {
                setParkingSpotID('');
            }
        }
    }

    return (
        <div>
            {
                newsBannerEnabled ?
                    <div className="news-banner">
                        {t(concept.toLocaleLowerCase() !== 'none' ? `${concept.toLocaleLowerCase()}_newsBannerMessage` : "newsBannerMessage")}
                    </div> : null
            }
            
            <div className={`auth-inner content ${backgroudStyle}`}>
                <div className={backgroudStyle}>
                    {isLoading ? <LoadingSpinner concept={concept} /> :
                        <div>
                            <div className="brand-logo">
                                {
                                    concept === OBS_CONCEPT_CODE ? <a
                                        aria-label="Outback Steakhouse"
                                        href="https://www.outback.com/"
                                        target="_self"
                                        analytics-label="outback.com">
                                        <span className="glf glf-logo_outback"></span>
                                    </a> : <> {
                                        concept === BFG_CONCEPT_CODE ?
                                            <a
                                                aria-label="Bonefish Grill"
                                                href="https://www.bonefishgrill.com/"
                                                target="_self"
                                                analytics-label="bonefishgrill.com">
                                                <span className="glf glf-logo_bonefish"></span>
                                            </a> : <> {
                                                concept === CIG_CONCEPT_CODE ?
                                                    <a
                                                        aria-label="Carrabba's Italian Grill"
                                                        href="https://www.carrabbas.com/"
                                                        target="_self"
                                                        analytics-label="carrabbas.com">
                                                        <span className="glf glf-logo_carrabbas"></span>
                                                    </a> : <></>
                                            } </>
                                    } </>
                                }
                            </div>

                            <form>
                                <div>
                                    <h3 className="title uppercase">
                                        {t(`${concept.toLocaleLowerCase()}_pageTitles.checkinPageTitle`)}
                                    </h3>
                                    <div className="mb-2 trip-ctrl-btn">
                                        <div className="form-group row form-container">
                                            <label className="description">
                                                {t(`${concept.toLocaleLowerCase()}_labels.parkingSpotDescriptionText`)}
                                            </label>
                                            <div className="def-number-input">
                                                <input
                                                    maxLength={2}
                                                    className={spotinputStyle}
                                                    name="parkingSpotID"
                                                    value={parkingSpotID}
                                                    onChange={handleNumChange}
                                                    type="number"
                                                    inputMode="numeric"
                                                    pattern="[0-9]*"
                                                />
                                                <span className="error">{errorMessage}</span>
                                            </div>
                                            <div className="no-spots-label">
                                                <BsFillTelephoneFill className="icon" />
                                                <label className="no-spot">
                                                    {t(`${concept.toLocaleLowerCase()}_labels.noSpotsAvailableText`)}
                                                </label>
                                            </div>
                                            {
                                                storePhoneNumber !== '' ?
                                                    <div>
                                                        <a className="phone-link" href={`tel:${formattedPhoneNumber(storePhoneNumber) ?? ''}`} target="_self">{formattedPhoneNumber(storePhoneNumber)}</a>
                                                    </div>
                                                    : null
                                            }
                                            <Button
                                                className={submitButtonStyle}
                                                type="submit"
                                                onClick={(event) => submitParkingInfo(event)}
                                                disabled={concept === 'NONE'}
                                            >
                                                {t(`${concept.toLocaleLowerCase()}_buttons.imHereButtonText`)}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CheckIn;
