import React from "react";
import "./loadingSpinner.scss";

function LoadingSpinner(props) {

  const spinnerStyle = props.concept === "OBS" ? 'obs-loading-spinner'
    : (props.concept === "BFG" ? 'bfg-loading-spinner'
      : (props.concept === "CIG" ? 'cig-loading-spinner' : 'loading-spinner'))
  return (
    <div className="spinner-container">
      <div className={spinnerStyle}></div>
    </div>
  );
}

export default LoadingSpinner;