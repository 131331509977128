import { isDev } from '../Common/constants';
import { uiGet } from '../Common/httpService';
import defaultTranslationJson from '../Translations/en/enUS-Translations.json';
import defaultFeatureFlagsJson from '../featureFlags.json';

export async function getTranslations(langCode: string): Promise<any> {
    try {
        if (isDev()) {
            return defaultTranslationJson;
        }

        const response = await uiGet(`/api/configurations/translations/${langCode}`);

        if (response && response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error occured!', error);
    }
    return null;
}

export function getDefaultTranslations(): any {
    return defaultTranslationJson;
}

export async function getFeatureFlags(): Promise<any> {
    try {
        return defaultFeatureFlagsJson;

        // TODO: Later cache the configurations and hook the backend

        //if (isDev()) {
        //    return defaultFeatureFlagsJson;
        //}

        // const response = await uiGet('/api/configurations/feature-flags');

        // if (response && response.status === 200) {
        //     return response.data;
        // }

    } catch (error) {
        console.error('Error occured!', error);
    }
    return null;
}
