export const DEV_OBS_SITE = 'dev-checkin.outback.com';
export const QA_OBS_SITE = 'qa-checkin.outback.com';
export const OBS_SITE = 'checkin.outback.com';
export const OBS_BACKGROUND_COLOR = '#f7f2e8';
export const OBS_CONCEPT_CODE = 'OBS';

export const DEV_CIG_SITE = 'dev-checkin.carrabbas.com';
export const QA_CIG_SITE = 'qa-checkin.carrabbas.com';
export const CIG_SITE = 'checkin.carrabbas.com';
export const CIG_BACKGROUND_COLOR = '#fff';
export const CIG_CONCEPT_CODE = 'CIG';

export const DEV_BFG_SITE = 'dev-checkin.bonefishgrill.com';
export const QA_BFG_SITE = 'qa-checkin.bonefishgrill.com';
export const BFG_SITE = 'checkin.bonefishgrill.com';
export const BFG_BACKGROUND_COLOR = '#fff';
export const BFG_CONCEPT_CODE = 'BFG';
export const US_ENGILISH_LANGCODE = 'enUS';

export const isDev = () => process.env.NODE_ENV !== "production";