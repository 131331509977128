import { ISpotData } from '../Models/SpotData';
import { uiPost } from '../Common/httpService';

export async function submitSpotData(spotData: ISpotData): Promise<any> {
  try {
    const response = await uiPost('/api/events', spotData);
    if (response && response.status === 202) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error occured!', error);
  }
  return false;
}
