import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './confirmation.scss';
import { useTranslation } from 'react-i18next';
import '../../Translations/i18n';
import {
  BFG_BACKGROUND_COLOR,
  BFG_CONCEPT_CODE,
  CIG_BACKGROUND_COLOR,
  CIG_CONCEPT_CODE,
  OBS_BACKGROUND_COLOR,
  OBS_CONCEPT_CODE
} from '../../Common/constants';

function Confirmation() {
  const { t } = useTranslation();
  const location = useLocation();

  const [orderId, setOrderId] = useState('');
  const [concept, setConcept] = useState('NONE');
  const [backgroudStyle, setBackgroudStyle] = useState('background');

  useEffect(() => {
    const queryParams = location.search;
    const searchParams = new URLSearchParams(queryParams);
    const p_orderId: string | null = searchParams.get('order-id');
    const p_concept: string | null = searchParams.get('concept-code');

    let locationCode;
    let conceptCode;
    let storeId;

    if (p_orderId && p_orderId.length === 18) {
      locationCode = p_orderId.substring(2, 6);
      conceptCode = parseInt(p_orderId.substring(0, 2), 10).toString();
      storeId = `${conceptCode}_${locationCode}`;
    }

    if (p_concept) {
      setConcept(p_concept);
    }

    if (p_concept) {
      switch (p_concept.toLocaleUpperCase()) {
        case BFG_CONCEPT_CODE:
          setConcept(BFG_CONCEPT_CODE);
          document.body.style.backgroundColor = BFG_BACKGROUND_COLOR;
          setBackgroudStyle('bfg-background');
          break;

        case OBS_CONCEPT_CODE:
          setConcept(OBS_CONCEPT_CODE);
          document.body.style.backgroundColor = OBS_BACKGROUND_COLOR;
          setBackgroudStyle('obs-background');
          break;

        case CIG_CONCEPT_CODE:
          setConcept(CIG_CONCEPT_CODE);
          document.body.style.backgroundColor = CIG_BACKGROUND_COLOR;
          setBackgroudStyle('cig-background');
          break;

        default:
          setConcept('NONE');
      }
    }

    if (!p_orderId) {
      return
    } else {
      setOrderId(p_orderId ?? '')
    }
  }, [location.search]);

  return (
    <div className={backgroudStyle}>
      <div className="brand-logo">
        {
          concept === OBS_CONCEPT_CODE ? <a
            aria-label="Outback Steakhouse"
            href="https://www.outback.com/"
            target="_self"
            analytics-label="outback.com">
            <span className="glf glf-logo_outback"></span>
          </a> : <> {
              concept === BFG_CONCEPT_CODE ?
              <a
                aria-label="Bonefish Grill"
                href="https://www.bonefishgrill.com/"
                target="_self"
                analytics-label="bonefishgrill.com">
                <span className="glf glf-logo_bonefish"></span>
              </a> : <> {
                  concept === CIG_CONCEPT_CODE ?
                  <a
                    aria-label="Carrabba's Italian Grill"
                    href="https://www.carrabbas.com/"
                    target="_self"
                    analytics-label="carrabbas.com">
                    <span className="glf glf-logo_carrabbas"></span>
                  </a> : <></>
              } </>
          } </>
        }
      </div>
      <h3 className="title uppercase">
        {t(`${concept.toLocaleLowerCase()}_pageTitles.weWillBeRightOutTitle`)}
      </h3>
      <div className="">
        <label className="description">
          {t(`${concept.toLocaleLowerCase()}_labels.hangTight`)}
        </label>
        <label className="description order-id-label">
          {`Order #${orderId}`}
        </label>
      </div>
    </div>
  );
};

export default Confirmation;
