import { useState } from "react";
import { getFeatureFlags } from "../Services/config-service";
import { isDev } from "../Common/constants";

function useFeatureFlags(defaultFeatureFlags: any) {
    const [value, setValue] = useState(defaultFeatureFlags);

    const isFeatureEnabled = async (flag: string): Promise<boolean> => {
        const configurations = await getFeatureFlags();
        setValue(configurations[flag]);

        if (isDev()) {
            console.log(`===== Feature Flags Check For ${flag} Is ${configurations[flag]} =====`,);
        }

        return getFeatureFlags()[flag];
    };

    return [value, isFeatureEnabled] as const;
}

export default useFeatureFlags;